import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { DataStore } from 'aws-amplify';
import dayjs from 'dayjs';
import {
  CourseRegistration,
  CourseRegistrationStatus,
  User,
  Group,
} from '../../../models/index';
import {
  listGroups, listGroupsForManager,
  deleteRegisteredCourse,
} from '../../DataStore/Services';
import { fetchRegisteredCourses } from './Services/Common';
import { getCurrentUser } from '../../Shared/Services/AuthService';
import * as styles from '../../../styles/admin/Browse.module.scss';
import closeIcon from '../../../../static/images/admin/close-icon.svg';
import { filterByGroup, filterByDate } from './CommonService';
import { formatDate, toaster } from '../../../services/utils';
import TableComponent from '../../Shared/Table/TableComponent';

function AssignedTabView() {
  const [groupList, setGroupList] = useState([]);
  const [assignedCourses, setAssignedCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const listGroupHandler = async () => {
    const groupData = await listGroups();
    if (groupData) {
      setGroupList(groupData);
    }
  };
  const fetchGroupsForManager = async (currentUser) => {
    const groups = await listGroupsForManager(currentUser?.attributes?.sub,
      currentUser?.attributes?.id);
    setGroupList(groups);
    return groups;
  };

  /**
   * fetch course registrations according to current user role
   *
   */
  const listAssignedCourses = async () => {
    setIsLoading(true);
    const currentUser = await getCurrentUser();
    let registeredCourses = await fetchRegisteredCourses();
    const cognitoGroups = currentUser?.signInUserSession?.idToken?.payload['cognito:groups'];
    if (cognitoGroups?.includes('admin')) {
      listGroupHandler();
    } else if (cognitoGroups?.includes('manager')) {
      // fetch all courses for the group where user is manager
      const managerGroups = await fetchGroupsForManager(currentUser);
      const selectedGroupNames = managerGroups.map((group) => group.name);
      registeredCourses = await filterByGroup(registeredCourses, managerGroups, selectedGroupNames);
    } else {
      navigate('/learner/');
    }
    const coursesWithUserDetails = registeredCourses.map((course, index) => {
      const returnObj = {
        key: index,
        ...course,
        sub: course?.user?.sub,
        courseName: course?.course?.courseName,
        email: course?.user?.email,
        createdAt: formatDate(course?.createdAt),
        status: course?.status,
        courseID: course?.courseID,
      };
      return returnObj;
    });
    setAssignedCourses(coursesWithUserDetails);
    setIsLoading(false);
  };

  const observeData = (Model) => DataStore.observe(Model).subscribe(async (msg) => {
    if (msg.opType) {
      await listAssignedCourses();
    }
  });

  useEffect(async () => {
    await listAssignedCourses();
    const courseRegistrationSubscription = observeData(CourseRegistration);
    const userSubscription = observeData(User);
    const groupSubscription = observeData(Group);

    return () => {
      courseRegistrationSubscription.unsubscribe();
      userSubscription.unsubscribe();
      groupSubscription.unsubscribe();
    };
  }, []);

  /**
   * soft delete requested course
   *
   * @param {*} record
   */
  const unassignCourse = async (record) => {
    const courseDeleteResponse = await deleteRegisteredCourse(record?.id);
    if (courseDeleteResponse) {
      toaster('Course unassigned successfully', 'success', 2);
    }
  };

  const columns = [
    {
      title: 'Course',
      dataIndex: 'courseName',
    },
    {
      title: 'User',
      dataIndex: 'email',
    },
    {
      title: 'Assigned On',
      dataIndex: 'createdAt',
      sortOrder: 'descend',
      sorter: (a, b) => dayjs(a.createdAt) - dayjs(b.createdAt),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, record) => (
        <div className={styles.rowFlex}>
          <span>{record?.status}</span>
          {
            record?.status !== CourseRegistrationStatus.COMPLETED
            && (
            <button
              type="button"
              className={styles.unAssign}
              onClick={() => unassignCourse(record)}
            >
              <img src={closeIcon} alt="close" />
            </button>
            )
          }
        </div>
      ),
    },
  ];

  const headers = columns.map((column) => ({ label: column.title, key: column.dataIndex }));

  const filterByStatus = async (dataList, _, status) => {
    const filterByStatusData = (status === 'All'
      ? dataList : dataList?.filter((data) => data.status === status)
    );
    return filterByStatusData;
  };
  const filterByDateHandler = async (data, options, selectedOption) => {
    const filteredData = await filterByDate(data, selectedOption);
    return filteredData;
  };
  return (
    <>
      <div className={styles.assignedCourses}>
        <p>
          Assigned courses
        </p>
      </div>
      <TableComponent
        globalSearchPlaceholder="Search by course name, email"
        displayColumns={columns}
        allData={assignedCourses}
        tableLoader={isLoading}
        headers={headers}
        fileName="Assigned Courses Report.csv"
        filters={{
          group: {
            name: 'Group',
            type: 'checkbox',
            options: groupList.map((group) => group.name),
            groupList,
            filterHandler: filterByGroup,
          },
          status: {
            name: 'Status',
            type: 'select',
            options: [...Object.values(CourseRegistrationStatus)
              .filter((status) => (status !== CourseRegistrationStatus.REQUESTED)), 'All'],
            filterHandler: filterByStatus,

          },
          datetime: {
            name: 'Date',
            type: 'date',
            filterHandler: filterByDateHandler,
          },
        }}
      />
    </>
  );
}
export default AssignedTabView;
